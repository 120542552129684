import React, { useEffect, useContext, useState } from "react";

import CartContext from "../contexts/Cart/Cart.context";
import { getItemsFromCheckout } from "../contexts/Cart/helpers";
import { CartItemSelector, CartCheckoutBox, Spinner, SEO } from "../components";
import withLayout from "../hoc/withLayout/withLayout";
import { useUserContext } from "../contexts/User/User.context";
import { i18nizePath } from "../utils/helpers";

const Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

const Cart = ({ location }) => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [cartUpdated, setCartUpdated] = useState(false);
  const {
    state: { checkout, errors, loading, promoCode: statePromoCode },
    actions: { applyPromo, retryPromo, onCheckout, addItems, createNewCart },
  } = useContext(CartContext);

  const {
    state: { user, loading: userLoading },
  } = useUserContext();

  const items = getItemsFromCheckout(checkout);

  const promoCode = items[0]?.discountAllocations[0]?.discountApplication?.code;

  useEffect(() => {
    if (userLoading) return null;
    const query = new window.URLSearchParams(location.search);
    const itemsReceived = query.get("items");

    if (itemsReceived) {
      if (items.length > 0 && !cartUpdated) {
        return createNewCart({ input: {} });
      }
      const decode = Buffer.from(itemsReceived, "base64").toString("ascii");
      const itemsDecoded = JSON.parse(decode);

      if (!cartUpdated && !loading && checkout.id) {
        const addToCartElements = Object.keys(itemsDecoded).reduce(
          (acc, variant) => {
            if (variant === "promoCode") return acc;
            return [
              ...acc,
              {
                id: variant,
                quantity: itemsDecoded[variant],
                product: {
                  title: "",
                },
              },
            ];
          },
          []
        );
        addItems(addToCartElements).then(() => {
          setLoadingProducts(false);
        });
        setCartUpdated(true);
      }

      if (
        itemsDecoded.promoCode &&
        !promoCode &&
        checkout?.lineItems?.edges.length > 0
      ) {
        applyPromo(itemsDecoded.promoCode, checkout);
      }
    }

    if (!itemsReceived && checkout.id) {
      setLoadingProducts(false);
    }
  }, [checkout]);

  useEffect(() => {
    if (typeof window === "undefined" || userLoading) return;
    if (items.length === 0) {
      if (statePromoCode.length > 0 && !loading) {
        applyPromo("", checkout);
      }
      const localizedPathname = i18nizePath(user.locale.code, "/cart");
      return window.history.pushState({}, "", localizedPathname);
    }

    let itemsDataToEncode = items.reduce((acc, item) => {
      return { ...acc, [item.variant.id]: item.quantity };
    }, {});

    if (promoCode) {
      itemsDataToEncode.promoCode = promoCode;
    }

    itemsDataToEncode = JSON.stringify(itemsDataToEncode);
    const encode = Buffer.from(itemsDataToEncode).toString("base64");
    const localizedPathname = i18nizePath(
      user.locale.code,
      `/cart?items=${encode}`
    );
    return window.history.pushState({}, "", localizedPathname);
  }, [items]);

  if (loadingProducts) return <Spinner />;

  return (
    <>
      <SEO title="Review your cart" />
      <div className="component-default-padding grid grid-cols-1 items-start justify-between gap-4 md:grid-cols-12">
        <div className="mb-5 md:col-start-1 md:col-end-8 md:text-left">
          <h4 className="mb-8 hidden text-2xl text-primary-color sm:block md:mb-10 md:text-3xl lg:text-4xl">
            Review your cart
          </h4>
          <div>
            <div>
              {items.length === 0 ? (
                <p>There are no products in your cart</p>
              ) : (
                <CartItemSelector items={items} size="medium" />
              )}
            </div>
          </div>
        </div>
        <div className="sticky top-40 md:col-start-9 md:col-end-13">
          <CartCheckoutBox />
        </div>
      </div>
    </>
  );
};

export default withLayout(Cart);
